import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["ensemble", "soloist", "conductor", "composer"]

  connect() {
    this.refreshStates() // Ensure correct state on load
    this.ensembleTarget.addEventListener("change", () => this.refreshStates())
  }

  refreshStates() {
    const disabled = this.ensembleTarget.checked
    this.soloistTarget.disabled = disabled
    this.conductorTarget.disabled = disabled
    this.composerTarget.disabled = disabled
  }
}
